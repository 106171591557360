///
// Button
///
.c-btn {
    display: inline-block;
    padding: rem(5px) rem(40px);
    font-size: rem(20px);
    font-family: $barlow-bold;
    letter-spacing: 3px;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 15px;
    transition: all 0.3s ease-in-out;
}

///
// Brown
///
.c-btn--brown {
    color: color('brown');
    border: 2px solid color('brown');

    @include link-states() {
        color: white;
        background-color: color('brown');
    }
}

///
// Orange
///
.c-btn--orange {
    color: white;
    background-color: color('orange');

    @include link-states() {
        color: color('orange');
        background-color: white;
    }
}

///
// White
///
.c-btn--white {
    color: white;
    border: 2px solid white;

    @include link-states() {
        color: color('orange');
        background-color: white;
    }
}

///
// More
///
.c-btn--more {
    display: block;
    max-width: 192px;
    margin-top: rem(20px);
    margin-bottom: rem(20px);
    margin-left: auto;
    margin-right: auto;
}

///
// Scroll
//
.c-btn--scroll {
    position: fixed;
    right: 15px;
    bottom: 15px;
    padding: rem(15px) rem(20px);
    color: white;
    background-color: color('orange');
    background-image: url('../../dist/images/asfalt-dark.png');
    background-position: center;
    background-repeat: repeat;
    border-radius: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.35s ease-in-out;

    @include link-states() {
        background-color: color('blue');
    }

    &.is-visible {
        visibility: visible;
        opacity: 1;
    }
}
