///
// Toggle
///
.c-toggle {
    position: absolute;
    top: neg(13px);
    right: 15px;
    width: 32px;
    height: 26px;
    padding: 0;
    background-color: transparent;
    border: 0;
    appearance: none;
    outline: none;

    // Medium Desktops
    @include media(md) {
        display: none;
    }

    &:before {
        content: 'Menu';
        position: absolute;
        top: neg(6px);
        left: neg(60px);
        font-size: rem(26px);
        font-family: $barlow-semi-bold;
        color: white;
        text-transform: uppercase;
    }
}

///
// Icon
///
.c-toggle__icon {
    display: inline-block;
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    width: 100%;
    height: 3px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    transition: transform 0.3s;

    &:after,
    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 3px;
        margin-left: auto;
        margin-right: auto;
        background-color: inherit;
        transition: transform 0.3s ease-in-out;
    }

    &:after {
        top: 8px;
    }

    &:before {
        bottom: 8px;
    }

    .is-open & {
        background-color: transparent;

        &:after,
        &:before {
            top: 0;
            background-color: white;
        }

        &:after {
            transform: rotate(-45deg);
        }

        &:before {
            transform: rotate(45deg);
        }
    }
}

///
// Dropdown
///
.c-toggle-dropdown {
    // Medium Max Desktops
    @include media-max(md) {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 32px;
        cursor: pointer;
    }

    &:after {
        // Medium Max Desktops
        @include media-max(md) {
            content: '\f107';
            font-size: rem(20px);
            font-family: 'Font Awesome Solid';
            color: white;

            .is-expanded & {
                content: '\f106';
            }
        }
    }
}