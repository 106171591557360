///
// Nav
///
.c-nav--primary {
    background-color: color('brown');

    // Medium Max Desktops
    @include media-max(md) {
        padding-top: rem(23px);
        padding-bottom: rem(23px);
    }

    // Medium Desktops
    @include media(md) {
        padding-top: rem(20px);
        padding-bottom: rem(20px);
    }

    .o-container {
        position: relative;
    }

    .c-info--phone {
        position: absolute;
        top: neg(13px);
        left: 15px;
        font-size: rem(20px);
        color: white;

        // Medium Desktops
        @include media(md) {
            display: none;
        }
    }
}

.c-nav--footer {
    margin-top: rem($base-gutter);
}

///
// Nav List
///
.c-nav__list {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;

    // Medium Desktops
    @include media(md) {
        display: flex;
        justify-content: space-around;
    }

    .c-nav--primary & {
        // Medium Max Desktops
        @include media-max(md) {
            display: none;
        }
    }

    .is-open & {
        // Medium Max Desktops
        @include media-max(md) {
            display: block;
            padding-top: rem(60px);
        }
    }
}

///
// Nav Item
///
.c-nav__item {
    position: relative;

    &:hover {
        .c-nav__dropdown {
            // Medium Desktops
            @include media(md) {
                display: block;
            }
        }
    }

    + .c-nav__item {
        // Medium Max Desktops
        @include media-max(md) {
            margin-top: rem(10px);
        }
    }

    .c-nav--primary & {
        // Medium Desktops
        @include media(md) {
            display: inline-block;
            vertical-align: top;
        }
    }

    a {
        font-size: rem(21px);
        font-family: $barlow-bold;
        color: white;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-decoration: none;

        @include link-states() {
            color: color('orange');
        }

        .c-nav--footer & {
            font-family: $barlow-regular;
        }
    }
}

///
// Dropdown
///
.c-nav__dropdown {
    display: none;
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;

    // Medium Max Desktops
    @include media-max(md) {
        margin-top: rem(halve($base-gutter));
        padding-left: rem(halve($base-gutter));
    }

    // Medium Desktops
    @include media(md) {
        position: absolute;
        top: 100%;
        left: neg(15px);
        z-index: z('dropdown');
        min-width: 320px;
        padding: rem(halve($base-gutter)) rem(halve($base-gutter));
        background-color: color('brown');
    }

    .c-nav__item {
        display: block;

        + .c-nav__item {
            // Medium Desktops
            @include media(md) {
                margin-top: rem(10px);
            }
        }
    }

    .is-expanded & {
        display: block;
    }
}