.gform_wrapper {
    font-size: rem(22px) !important;

    h2 {
        font-size: rem(24px) !important;
        font-family: $barlow-semi-bold !important;

        // Medium Desktops
        @include media(md) {
            font-size: rem(28px) !important;
        }
    }
}

.gfield_label {
    font-family: $barlow-medium !important;
    font-weight: 500 !important;
}

.gform_button {
    display: inline-block !important;
    padding: rem(5px) rem(40px) !important;
    font-size: rem(20px) !important;
    font-family: $barlow-bold !important;
    color: white !important;
    letter-spacing: 3px !important;
    text-decoration: none !important;
    text-transform: uppercase !important;
    background-color: color('orange') !important;
    border: 0 !important;
    border-radius: 15px !important;
    appearance: none !important;
    transition: all 0.3s ease-in-out;

    @include link-states() {
        background-color: color('blue') !important;
    }
}
