.wu-day-title {
    @include font-size('h3');
    color: white;
    text-transform: uppercase;
}

.wu-cond-text,
.wu-cond-highlow,
.wu-cond-pop,
.wu-cond-high,
.wu-cond-split,
.wu-cond-low {
    font-size: rem(22px);
    color: white;
    white-space: nowrap;
}

.wu-cond-split {
    display: inline-block;
    margin-left: rem(5px);
    margin-right: rem(5px);
}

.wu-cond-highlow {
    margin-top: rem(5px);
    margin-bottom: rem(5px);
}

.wu-cond-pop {
    margin-left: 0;

    &:after {
        content: 'Chance of Precip.';
        padding-left: rem(5px);
    }
}

.wu-icon {
    img {
        width: 100%
    }
}

.wu-copyright {
    display: none;
}