///
// Sale
///
.c-sale {
    margin-left: neg(rem(halve($base-gutter)));
    margin-right: neg(rem(halve($base-gutter)));

    // Medium Desktops
    @include media(md) {
        display: flex;
        flex-wrap: wrap;
    }

    + .c-sale {
        margin-top: rem($base-gutter);
        padding-top: rem($base-gutter);
        border-top: 1px solid $base-font-color;
    }
}

///
// Top
///
.c-sale__top {
    width: 100%;
    margin-bottom: rem(20px);
    padding-left: rem(halve($base-gutter));
    padding-right: rem(halve($base-gutter));
}

///
// Gallery & Info
///
.c-sale__gallery,
.c-sale__info {
    display: flex;
    width: 100%;
    padding-left: rem(halve($base-gutter));
    padding-right: rem(halve($base-gutter));

    // Medium Desktops
    @include media(md) {
        width: 50%;
    }
}

///
// Gallery
///
.c-sale__gallery {
    // Medium Max Desktops
    @include media-max(md) {
        margin-bottom: rem(20px);
    }

    .is-odd & {
        // Medium Desktops
        @include media(md) {
            order: 1;
        }
    }

    .is-even & {
        // Medium Desktops
        @include media(md) {
            order: 2;
        }
    }
}

///
// Info
///
.c-sale__info {
    .is-odd & {
        // Medium Desktops
        @include media(md) {
            order: 2;
        }
    }

    .is-even & {
        // Medium Desktops
        @include media(md) {
            order: 1;
        }
    }
}

///
// Inner
///
.c-sale__inner {
    width: 100%;
}

///
// Contact
///
.c-sale__contact {
    margin-top: rem(20px);

    .c-btn + .c-btn {
        margin-left: rem(10px);
    }
}
