body {
    position: relative;
    margin: 0;
    font-size: $base-font-size;
    font-family: $barlow-light;
    line-height: $base-line-height;
    color: $base-font-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

hr {
    width: 100%;
    height: 1px;
    margin-top: rem($base-gutter);
    margin-bottom: rem($base-gutter);
    background-color: $base-font-color;
    border: 0;

    // Medium Desktops
    @include media(md) {
        margin-top: rem(double($base-gutter));
        margin-bottom: rem(double($base-gutter));
    }
}

ol:not([class]),
ul:not([class]) {
    margin-top: 0;
    margin-bottom: rem($base-gutter);
}

ul:not([class]) {
    padding-left: rem(10px);
    list-style-type: none;

    li:not([class]) {
        position: relative;
        padding-left: rem(20px);
        letter-spacing: 2px;

        &:before {
            content: '\2022';
            position: absolute;
            top: neg(10px);
            left: 0;
            font-size: rem(32px);
            color: color('orange');
        }

        + li:not([class]) {
            margin-top: rem(halve($base-gutter));
        }
    }
}


.flex {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    align-items: center;
}

.fl-btw {
    justify-content: space-between;

    li {
        &:not(:last-child) {
            margin-right: rem(20px);
        }
    }

    @include media-max(md) {
        justify-content: center;
        flex-flow: column wrap;

        li:not(:last-child) {
            margin-right: 0;
            margin-bottom: 15px;
        }
    }
}
