///
// Header
///
.c-header {
    padding-top: rem(halve($base-gutter));
    padding-bottom: rem(halve($base-gutter));
    background-color: color('blue');
    background-image: url('../../dist/images/asfalt-dark.png');
    background-position: center;
    background-repeat: repeat;

    // Medium Max Desktops
    @include media-max(md) {
        padding-bottom: rem(50px);
    }

    .o-container-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 1200px;
        margin-right: auto;
        margin-left: auto;
        padding-left: rem(halve($base-gutter));
        padding-right: rem(halve($base-gutter));

        // Medium Max Desktops
        @include media-max(md) {
            justify-content: center;
        }
    }

    .c-info--phone {
        font-size: rem(28px);
        font-family: $barlow-bold;
        color: white;

        // Medium Max Desktops
        @include media-max(md) {
            display: none;
        }

        @include link-states() {
            color: color('orange');
        }
    }

    .o-container-flex {
        max-width:  1500px;
    }

    ul li a {
        font-size: 16px !important;
    }
}
