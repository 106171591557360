///
// Soliloquy
///
.soliloquy-thumbnails {
    margin-top: rem(20px) !important;
}

#soliloquybox-thumbs {
    ul {
        li {
            padding-left: 0 !important;

            &:before {
                display: none !important;
            }
        }
    }
}

.soliloquy-container {
    .c-sale & {
        width: 100% !important;
        max-width: 100% !important;
        max-height: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.soliloquy-image {
    .c-sale & {
        width: 100% !important;
    }
}

///
// jQuery UI
///
.ui-datepicker-title {
    overflow: hidden;
}

.ui-datepicker-month,
.ui-datepicker-year {
    width: 50%;
    margin-top: 0 !important;
    color: $base-font-color !important;
}

.ui-datepicker-month {
    float: left;
}

.ui-datepicker-year {
    float: right;
}

.ui-datepicker {
    td {
        a {
            font-weight: 500 !important;
            color: $base-font-color !important;
            text-shadow: none !important;
        }
    }
}

///
// MailChimp
///
#mc_embed_signup {
    width: 100%;
    max-width: 413px;
    margin-left: auto;
    margin-right: auto;

    input {
        height: 49px;
    }

    .o-col--2 {
        margin-top: 0 !important;
        padding-left: 0;
    }
}

#mc-embedded-subscribe {
    width: 100%;
    font-size: rem(24px);
    font-family: $barlow-light;
    color: white;
    background-color: color('brown');
    border: 0;
    appearance: none;

    @include link-states() {
        background-color: color('orange');
    }
}
