///
// Rates
///
.c-rates {
    display: flex;
    flex-wrap: wrap;
    margin-left: neg(rem(halve($base-gutter)));
    margin-right: neg(rem(halve($base-gutter)));
}

///
// Rate
///
.c-rate {
    position: relative;
    width: 100%;
    margin-top: rem(90px);
    padding-left: rem(halve($base-gutter));
    padding-right: rem(halve($base-gutter));
    color: white;

    // Medium Desktops
    @include media(md) {
        width: 50%;
    }

    &:hover {
        // Medium Desktops
        @include media(md) {
            &:before {
                animation: shake 0.82s cubic-bezier(.36,.07,.19,.07) infinite;
            }
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: neg(65px);
        left: 0;
        right: 0;
        width: 80px;
        height: 123px;
        margin-left: auto;
        margin-right: auto;
        background-image: url('../../dist/images/tiki-man-angry.svg');
        background-position: center;
        background-repeat: no-repeat;
    }

    td {
        font-size: rem(22px);
    }

    strong {
        font-family: $barlow-bold;
    }

    .c-btn--orange {
        @include link-states() {
            color: color('orange');
            background-color: white;
        }
    }
}

///
// Inner
///
.c-rate__inner {
    height: 100%;
    padding: rem(80px) rem($base-gutter) rem($base-gutter);
    background-color: color('blue');
    background-image: url('../../dist/images/asfalt-dark.png');
    background-position: center;
    background-repeat: repeat;

    // Medium Desktops
    @include media(md) {
        padding-left: rem(double($base-gutter));
        padding-right: rem(double($base-gutter));
    }
}