.u-hide {
    // Medium Max Desktops
    @include media-max(md) {
        display: none;
    }
}

@each $breakpoint in map-keys($breakpoints) {
    @include media($breakpoint) {
        .u-hide\@#{$breakpoint} {
            display: none;
        }
    }
}