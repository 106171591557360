///
// White
///
.u-font-white {
    color: white;
}

///
// Blue
///
.u-font-blue {
    color: color('blue');
}