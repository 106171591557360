///
// Section
///
.c-section {
    padding-top: rem($base-gutter);
    padding-bottom: rem($base-gutter);

    // Medium Desktops
    @include media(md) {
        padding-top: rem(double($base-gutter));
        padding-bottom: rem(double($base-gutter));
    }
}

///
// Main
///
.c-section--main {
    .c-btn--orange {
        @include link-states() {
            color: white;
            background-color: color('blue');
        }
    }
}

///
// Orange
///
.c-section--orange {
    color: white;
    background-color: color('orange');
    background-image: url('../../dist/images/asfalt-dark.png');
    background-position: center;
    background-repeat: repeat;
}

///
// Blue
///
.c-section--blue {
    color: white;
    background-color: color('blue');
    background-image: url('../../dist/images/asfalt-dark.png');
    background-position: center;
    background-repeat: repeat;
}

///
// Background
///
.c-section--background {
    padding-top: 0;
    padding-bottom: 0;

    .o-col--6\@md:first-child {
        padding: rem($base-gutter) rem(halve($base-gutter));

        // Medium Desktops
        @include media(md) {
            padding: rem(double($base-gutter));
        }
    }

    .o-col--6\@md:last-child {
        padding: 0;
    }

    .o-col__inner {
        // Medium Desktops
        @include media(md) {
            max-width: 530px;
            margin-left: auto;
            margin-right: 0;
        }
    }
}

///
// Invert
///
.c-section--invert {
    padding-top: 0;
    padding-bottom: 0;

    .o-col--6\@md:last-child {
        padding: rem($base-gutter) rem(halve($base-gutter));

        // Medium Desktops
        @include media(md) {
            padding: rem(double($base-gutter));
        }
    }

    .o-col--6\@md:first-child {
        padding: 0;
    }

    .o-col__inner {
        // Medium Desktops
        @include media(md) {
            max-width: 530px;
            margin-left: 0;
            margin-right: auto;
        }
    }
}

///
// Background Full
///
.c-section--background-full {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

///
// Activities
///
.c-section--activities {
    .o-col__inner {
        padding: rem($base-gutter);
        background-color: rgba(white, 0.8);
        border-radius: 20px;

        // Medium Desktops
        @include media(md) {
            padding: rem(double($base-gutter));
        }
    }
}

///
// Location
///
.c-section--location {
    font-family: $barlow-regular;
    color: white;

    a {
        color: inherit;
    }

    .c-btn {
        margin-top: rem(20px);
    }

    .c-info:not(.c-info--address) {
        display: inline-block;
    }

    .c-info {
        font-size: rem(28px);
    }

    .c-info--address {
        display: block;
        margin-bottom: rem(20px);
    }

    .c-info--phone {
        @include link-states() {
            color: color('blue');
        }
    }
}

///
// Weather
///
.c-section--weather {
    background-color: color('brown');
    background-image: url('../../dist/images/dark-wood.png');
    background-position: center;
    background-repeat: repeat;

    .o-col--3\@md:not(:first-child) {
        // Medium Max Desktops
        @include media-max(md) {
            display: none;
        }
    }
}

///
// Header
///
.c-section__header {
    margin-bottom: rem($base-gutter);

    // Medium Desktops
    @include media(md) {
        margin-bottom: rem(double($base-gutter));
    }

    h2 {
        margin-bottom: 0;
    }
}