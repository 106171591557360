///
// Custom fonts
///

// Example
// @font-face {
//  font-family: 'questrialregular';
//  src: url('../../dist/fonts/questrial-regular-webfont.woff2') format('woff2'),
//       url('../../dist/fonts/questrial-regular-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
// }

@font-face {
    font-family: 'barlow_condensedbold';
    src: url('../../dist/fonts/barlowcondensed-bold-webfont.woff2') format('woff2'),
         url('../../dist/fonts/barlowcondensed-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'barlow_condensedlight';
    src: url('../../dist/fonts/barlowcondensed-light-webfont.woff2') format('woff2'),
         url('../../dist/fonts/barlowcondensed-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'barlow_condensedmedium';
    src: url('../../dist/fonts/barlowcondensed-medium-webfont.woff2') format('woff2'),
         url('../../dist/fonts/barlowcondensed-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'barlow_condensedregular';
    src: url('../../dist/fonts/barlowcondensed-regular-webfont.woff2') format('woff2'),
         url('../../dist/fonts/barlowcondensed-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'barlow_condensedsemibold';
    src: url('../../dist/fonts/barlowcondensed-semibold-webfont.woff2') format('woff2'),
         url('../../dist/fonts/barlowcondensed-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'phosphor_cgregular';
    src: url('../../dist/fonts/phosphor_cg-webfont.woff2') format('woff2'),
         url('../../dist/fonts/phosphor_cg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome';
    font-style: normal;
    font-weight: 400;
    src: url("../../dist/fonts/fa-regular-400.woff2") format("woff2"), 
         url("../../dist/fonts/fa-regular-400.woff") format("woff"), 
         url("../../dist/fonts/fa-regular-400.svg#fontawesome") format("svg"); 
}

@font-face {
    font-family: 'Font Awesome Solid';
    font-style: normal;
    font-weight: 900;
    src: url("../../dist/fonts/fa-solid-900.woff2") format("woff2"), 
         url("../../dist/fonts/fa-solid-900.woff") format("woff"), 
         url("../../dist/fonts/fa-solid-900.svg#fontawesome") format("svg");
}

@font-face {
    font-family: 'Font Awesome Brands';
    font-style: normal;
    font-weight: 400;
    src: url("../../dist/fonts/fa-brands-400.woff2") format("woff2"), 
         url("../../dist/fonts/fa-brands-400.woff") format("woff"), 
         url("../../dist/fonts/fa-brands-400.svg#fontawesome") format("svg");
}