///
// Footer
///
.c-footer {
    padding-top: rem($base-gutter);
    padding-bottom: rem($base-gutter);
    background-color: color('blue');
    background-image: url('../../dist/images/asfalt-dark.png');
    background-position: center;
    background-repeat: repeat;

    // Medium Desktops
    @include media(md) {
        padding-top: rem(double($base-gutter));
        padding-bottom: rem(double($base-gutter));
    }

    hr {
        background-color: white;
    }

    .c-social-links {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: rem($base-gutter);
    }

    .c-social-link {
        margin-left: rem(10px);
        margin-right: rem(10px);
    }

    .c-social-link--rv {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-left: rem(10px);
        margin-right: rem(10px);
        background-image: url('../../dist/images/rv-park-reviews.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        // Medium Desktops
        @include media(md) {
            width: 60px;
            height: 60px;
            background-size: auto;
        }
    }

    .fab {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        font-size: rem(18px);
        color: color('blue');
        background-color: white;
        border-radius: 50%;
        transition: all 0.35s ease-in-out;

        // Medium Desktops
        @include media(md) {
            width: 60px;
            height: 60px;
            font-size: rem(34px);
        }

        &:hover {
            color: white;
            background-color: color('orange');
        }
    }
}

///
// Copyright
///
.c-footer__copyright {
    font-size: rem(18px);
    color: white;

    a {
        font-family: $barlow-bold;
        color: inherit;

        @include link-states() {
            color: color('orange');
        }
    }
}
