input,
select,
textarea {
    width: 100%;
    padding: rem(10px);
    font-size: rem(22px);
    border: 1px solid color('grey', 'medium');
    border-radius: 0;
}

input[type="submit"] {
    width: auto;
}

::-webkit-input-placeholder {
    font-size: rem(20px);
    color: color('brown');
    text-transform: uppercase;
}

::-moz-placeholder {
    font-size: rem(20px);
    color: color('brown');
    text-transform: uppercase;
}

:-ms-input-placeholder {
    font-size: rem(20px);
    color: color('brown');
    text-transform: uppercase;
}

:-moz-placeholder {
    font-size: rem(20px);
    color: color('brown');
    text-transform: uppercase;
}
