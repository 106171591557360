///
// Row
///
.o-row {
    @include make-row;
}

///
// Flex
///
.o-row-flex {
    display: flex;
    flex-wrap: wrap;
    margin-left: neg(rem(halve($base-gutter)));
    margin-right: neg(rem(halve($base-gutter)));
    @include clearfix;

    // Medium Desktops
    @include media(md) {
        flex-wrap: nowrap;
    }
}
