///
// Container
///
.o-container {
    @include make-container;
}

///
// Fluid
///
.o-container-fluid {
    max-width: 100%;
    padding-left: rem(halve($base-gutter));
    padding-right: rem(halve($base-gutter));
}