///
// Tablepress
///
.tablepress {
    td {
        width: 50%;
        padding: rem(5px) 0 !important;
        border-top: 0 !important;
    }

    caption {
        a {
            font-size: rem(18px);
            font-family: $barlow-bold;
            color: inherit;
        }
    }
}

///
// Name
///
.tablepress-table-name {
    font-size: rem(38px) !important;
    color: white !important;
    text-align: center;
}
