///
// Hero
///
.c-hero {
    position: relative;
    //height: 35vh;
    background-color: color('orange');

    // Medium Desktops
    @include media(md) {
        //height: 70vh;
    }
}