///
// Logo
///
.c-logo {
    &.c-logo--hero {
        display: block;
        position: absolute;
        top: neg(30px);
        left: 0;
        right: 0;
        z-index: z('demigod');
        width: 140px;
        height: auto;
        margin-left: auto;
        margin-right: auto;

        // Medium Desktops
        @include media(md) {
            top: neg(50px);
            width: 250px;
        }

        .home & {
            animation: shake 0.82s cubic-bezier(.36,.07,.19,.07) both;
        }

        &:not(.home) {
            &:hover {
                animation: shake 0.82s cubic-bezier(.36,.07,.19,.07) both;
            }
        }
    }

    &.c-logo--footer {
        display: block;
        width: 215px;
        margin-left: auto;
        margin-right: auto;
    }
}
