h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: rem(20px);
    font-weight: 500;
    color: color('orange');
    line-height: 1;
}

h1,
h2,
h3,
h5,
h6 {
    letter-spacing: 4px;
}

h1,
h2,
h3 {
    font-family: $phosphor;
}

h4,
h5,
h6 {
    font-family: $barlow-semi-bold;
}

h1 {
    @include font-size('h1');
}

h2 {
    @include font-size('h2');
}

h3 {
    @include font-size('h3');
}

h4 {
    @include font-size('h4');
    letter-spacing: 2px;
}

h5 {
    @include font-size('h5');
}

h6 {
    @include font-size('h6');
}

p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: rem(22px);
    letter-spacing: 2px;
}

p:not(:last-child) {
    margin-bottom: rem(20px);
}

b,
strong {
    font-family: $barlow-medium;
    font-weight: 500;
}

a {
    font-family: $barlow-bold;
    color: color('blue');
    text-decoration: none;
}

li:not([class]) {
    font-size: rem(22px);
}
